<template>
  <div class="padding-page">
    <CommonTitle title="短信内容" style="padding: 12px 0" />
    <van-field
      v-model="message"
      rows="6"
      autosize
      label=""
      type="textarea"
      maxlength="500"
      placeholder="请复制含有发票信息的短信，并粘贴到此处"
      show-word-limit
    />
    <div class="btns">
      <van-button @click="clear">清空</van-button>
      <!-- <van-button @click="paste">粘贴</van-button> -->
    </div>
    <div class="bottom-btn">
      <van-button type="primary" @click="onClick">确认收取</van-button>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@components/CommonTitle.vue";
import { sendMessage } from "../../http/api";
export default {
  name: "message",

  components: {
    CommonTitle,
  },

  data() {
    return {
      message: "",
    };
  },

  methods: {
    clear() {
      this.message = null;
    },
    onClick() {
      this.$toast.loading({
        message: "发票查验中...",
        forbidClick: true,
        duration: 0,
      });
      sendMessage({ text: this.message }).then((res) => {
        this.$toast.clear();
        if (!res.statusCode) {
          this.$toast(res.message);
          return;
        }
        if (res.data.length == 0) {
          this.$toast("未识别到有效内容");
          return;
        }
        this.$toast("收取成功");
        this.$router.go(-1);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 12px 16px;
  font-size: 13px;
  color: #36374a;
  line-height: 18px;
}
.btns {
  margin-top: 27px;
  text-align: right;
  .van-button {
    width: 88px;
    height: 32px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    margin-left: 24px;
    color: #333333;
    font-size: 16px;
  }
}
</style>
